<template>
    <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px); min-width:fit-content">
        <a-layout-content style="padding:0;">
            <div class="yj-conten " style="background: #fff;padding: 20px 20px 0;margin: 0;">
                <a-row class="yj-form">
                    <a-col :span="20">
                      
                    </a-col>



                    <a-col :span="4" class="textal_r" style="padding-top:0;">
                        <!--<a-button type="primary" @click="addRow()">配置</a-button>-->

                       <a-button type="primary" @click="() => (modalVisible = true)">选择模板</a-button>
                        <a-modal width="700px" v-model="modalVisible" title="荣誉模板">
                            <div>
                                <p style="text-align:center">
                                    <a-button type="primary" v-for="itemIndex in templateData" :key="itemIndex.ID" class="margin-r" @click="choose(itemIndex)" ghost>{{itemIndex.Name}}</a-button>
                                </p>
                            </div>
                            <div slot="footer"></div>
                        </a-modal>

                        <a-button   @click="GetPartnerHonerRuleCustomerInfo"><a-icon type="edit"></a-icon>积分规则</a-button>
                        <a-modal width="600px" v-model="modalVisibleScore" title="设置积分规则">
                            <a-form-model >
                                <a-row type="flex" justify="center" align="center">
                                  <a-col :span="8">  <a-form-model-item label="拉新">
                                    <a-input-number :min="0" placeholder="" v-model="PullNew" :precision="0" />
                                </a-form-model-item></a-col>
                                <a-col :span="6">   <a-form-model-item label="意向">
                                    <a-input-number :min="0" placeholder="" v-model="Intention" :precision="0" />
                                </a-form-model-item></a-col>
                                </a-row>
                                <a-row type="flex" justify="center">
                                    <a-col :span="8">
                                        <a-form-model-item label="咨询">
                                            <a-input-number :min="0" placeholder="" v-model="Consult" :precision="0" />
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="6">
                                        <a-form-model-item label="成交">
                                            <a-input-number :min="0" placeholder="" v-model="Deal" :precision="0" />
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="6">
                                        <a-form-model-item label="阅读">
                                            <a-input-number :min="0" placeholder="" v-model="Read" :precision="0" />
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                            
                               
                            </a-form-model>
                      
                            <div slot="footer"  >      <a-form-model-item label="" class="textal_c">
                                <a-button type="primary" size="large" @click="EditPartnerHonerRuleCustomer">
                                    确定
                                </a-button>
                            </a-form-model-item></div>
                        </a-modal>


                    </a-col>
                </a-row>
                <a-table :columns="columns" bordered
                         rowKey="ID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         style=" min-height: calc(100vh - 234px);min-width:max-content"
                         class="yj-table-img">
                    <span slot="hlevel" slot-scope="text, record" style="font-weight:bolder;">
                        LV{{record.Rule.HonorLevel}}
                    </span>
                    <span slot="InfoReport" slot-scope="text, record">
                        <img style="width:50px; height:50px;" :src="Getsrc(record)">
                        <br />
                        <small>{{record.Rule.HonorName}}</small>
                    </span>
                    <span slot="Integral" slot-scope="text, record">
                        <small>{{GetIntegral(record.Rule.Integral, record.Rule.HonorLevel)}}</small>
                    </span>
                    <div slot="action" slot-scope="text, record" style="text-align:left;line-height:1.6rem;">
                        <span v-for="itemIndex in record.RuleIndex" :key="itemIndex.ID">
                            {{itemIndex.IndexName}}积分：+{{itemIndex.Integral}}
                            <br />
                        </span>
                    </div>
                    <span slot="op" slot-scope="text, record" v-show="record.Rule.HonorLevel>0">
                        <a @click="jump(record)" style="cursor:pointer;">编辑规则</a>
                    </span>
                </a-table>
            </div>
        </a-layout-content>
        <a-modal width="800px" v-model="HonerRule" title="编辑规则" class="honer-rule-footerno">
            <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px);min-width:fit-content">
                <a-layout-content style="padding:0;">
                    <div class="yj-conten " style="background: #fff;padding: 20px ;margin: 0;">

                        <a-row type="flex">
                            <a-col flex="220px" style="text-align:center;" :span='5'>
                                <img :src="GetImgsrc(HonorLevel)" style="width:160px;" />
                                <p style="margin:10px 0 0">{{HonorName}}</p>
                            </a-col>
                            <a-col flex="auto" :span='15'>
                                <a-form-model-item>
                                    <a-form-model layout='inline'>

                                        <!--<a-form-model-item :label="itemIndex.IndexName" prop="ReadTarget" v-for="itemIndex in HonerRuleData.RuleIndex" :key="itemIndex.ID">
                                            <a-input-number :min="0" placeholder="" style="width:70px" v-model="itemIndex.Integral" /> <a-tooltip placement="topLeft" style="margin-left:10px;" :title="itemIndex.IndexName + tip + itemIndex.Integral"><a-icon type="question-circle" /></a-tooltip>
                                        </a-form-model-item>-->
                                        <a-form-model-item :label="StarAwardName">
                                            <a-input-number :min="0" placeholder="" style="width:70px" v-model="StarAward" :precision="2" /> <a-tooltip placement="topLeft" style="margin-left:10px;" :title="StarAwardName+tip+ StarAward"><a-icon type="question-circle" /></a-tooltip>
                                        </a-form-model-item>
                                        <a-form-model-item :label="GradeAwardName">
                                            <a-input-number :min="0" placeholder="" style="width:70px" v-model="GradeAward" :precision="2" /> <a-tooltip placement="topLeft" style="margin-left:10px;" :title="GradeAwardName+tip+ GradeAward"><a-icon type="question-circle" /></a-tooltip>
                                        </a-form-model-item>
                                        <a-divider />

                                        <a-form-model-item label="等级积分" prop="ReportTarget">
                                            <a-input-number :min="0" placeholder="" style="width:70px" v-model="Integral" />
                                        </a-form-model-item>
                                    </a-form-model>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                        <a-form-model-item label="" class="textal_c">
                            <div v-if="this.$route.query.rowType !='select'">
                                <a-button type="primary" size="large" @click="save();">
                                    保存
                                </a-button>
                            </div>
                        </a-form-model-item>

                    </div>
                </a-layout-content>
            </div>
            <!--<div style="background: #fff;padding: 20px ;margin: 0; ">
                    <a-form-model-item :label="itemIndex.IndexName" prop="ReadTarget" v-for="itemIndex in HonerRuleData.RuleIndex" :key="itemIndex.ID">
                        <a-input-number :min="0" placeholder="" style="width:70px" v-model="itemIndex.LimitCount" />
                    </a-form-model-item>
                <a-form-model-item label="积分" prop="ReportTarget">
                    <a-input-number :min="0" placeholder="" style="width:70px" v-model="Integral" />
                </a-form-model-item>
                <a-form-model-item label="" class="textal_c">
                    <div v-if="this.$route.query.rowType !='select'">
                        <a-button type="primary" @click="save();">
                            保存
                        </a-button>
                    </div>
                </a-form-model-item>

            </div>-->
            <div slot="footer"></div>
        </a-modal>
    </div>


</template>
<script>
    import http from "@/Plugin/Http.js";

    export default {
        name: "M2Activity_List",
        data() {
            return {
                StarAwardName: '升1星奖励',
                GradeAwardName: '升级奖励',
                tip: '每个加',
                modalVisible: false,
                modalVisibleScore: false,
                HonerRule: false,
                columns: [
                    {
                        title: "级别",
                        dataIndex: "Rule.HonorLevel",
                        align: "center",
                        scopedSlots: { customRender: 'hlevel' }
                    },
                    {
                        title: "徽章",
                        align: "center",
                        dataIndex: "Rule.HonorName",
                        scopedSlots: { customRender: 'InfoReport' }
                    },
                    {
                        title: "所需积分",
                        align: "center",
                        scopedSlots: { customRender: 'Integral' }
                    },
                    {
                        title: "升1星奖励",
                        align: "center",
                        dataIndex: "Rule.StarAward",
                    },
                    {
                        title: "升级奖励",
                        align: "center",
                        dataIndex: "Rule.GradeAward",
                    },
                    {
                        title: "设置",
                        align: "center",
                        dataIndex: "Rule.Cash",
                        scopedSlots: { customRender: 'op' }
                    }
                ],
                tableData: [],
                HonerRuleData: [],
                templateData: [],
                Integral: null,
                HonorName: null,
                HonorLevel: null,
                StarAward: null,
                GradeAward: null,
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                HonerRuleCustomerID: 0,
                PullNew: 0,
                Consult: 0,
                Intention: 0,
                Deal: 0,
                Read: 0,
            };
        },
        props: {//组件属性
        },
        methods: {
            choose: function (record) {
                var id = record.ID;
                var self = this;
                self.$router.push({
                    name: "PartnerHonor_TmpList",
                    query: { ID: id }
                });
                //var id = record.ID;

                //var self = this;
                //var op = {
                //    url: "/PartnerModule/PartnerHonor/ChooseTemplate",
                //    data: {
                //        id: id
                //    },
                //    OnSuccess: function (data) {
                //        if (data) {
                //            self.$router.push({
                //                name: "PartnerHonor_Form",
                //                query: {}
                //            });
                //        } else {
                //            alert("选择模板失败");
                //        }
                //    }
                //};
                //http.Post(op);

            },
            Getsrc: function (record) {
                return "/image/honor/" + record.Rule.HonorLevel + ".png";
            },
            GetImgsrc: function (HonorLevel) {
                return "/image/honor/" + HonorLevel + ".png";
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            addRow() {
                var self = this;
                self.$router.push({
                    name: "PartnerHonor_Form",
                    query: { ID: 0 }
                });
            },
            jump: function (record) {
                var self = this;
                self.HonerRule = true;
                var op = {
                    url: "/PartnerModule/PartnerHonor/GetPartnerHonorItem",
                    data: {
                        id: record.Rule.ID
                    },
                    OnSuccess: function (data) {
                        console.log(data.data)
                        self.HonerRuleData = data.data;
                        self.HonerRuleData.RuleIndex.forEach(element => {
                            element.IndexName = element.IndexName + "积分";
                        })
                        self.Integral = data.data.Rule.Integral;
                        self.HonorName = data.data.Rule.HonorName;
                        self.HonorLevel = data.data.Rule.HonorLevel;
                        self.GradeAward = data.data.Rule.GradeAward;
                        self.StarAward = data.data.Rule.StarAward;
                    }
                };
                http.Post(op);
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerHonor/GetPartnerHonorList",
                    data: {

                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data;
                        console.log(self.tableData);
                        for (var i = 0; i < self.tableData.length; i++) {
                            self.tableData[i]["ID"] = i;
                        }
                    }
                };
                http.Post(op);
            },

            getTemplateData: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerHonor/GetTemplateList",
                    data: {

                    },
                    OnSuccess: function (data) {
                        self.templateData = data.data;
                    }
                };
                http.Post(op);
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },

            save: function () {
                var self = this;
                self.HonerRuleData.Rule.Integral = self.Integral;
                self.HonerRuleData.Rule.GradeAward = self.GradeAward;
                self.HonerRuleData.Rule.StarAward = self.StarAward;
                var op = {
                    url: "/PartnerModule/PartnerHonor/SaveSinglePartnerHonorItem",
                    data: {
                        model: self.HonerRuleData
                    },
                    OnSuccess: function (data) {
                        if (data.data == 1) {
                            self.$message.error('当前积分不能低于下级积分', 1.5, function () {
                            });

                        }
                        if (data.data == 2) {
                            self.$message.error('当前积分不能高于上级积分', 1.5, function () {
                            });

                        }
                        if (data.data == 0) {
                            self.$message.success('保存成功', 1.5, function () {
                                self.HonerRule = false;
                                self.getTableData();

                            });

                        }
                        self.$router.push({
                            name: "PartnerHonor_List"
                        });
                    }
                };
                http.Post(op);



            },
            GetIntegral: function (Integral, HonorLevel) {
                var rt = Integral;
                var self = this;
                self.tableData.forEach(e => {
                    if (e.Rule.HonorLevel == HonorLevel + 1) {
                        rt += '-' + (e.Rule.Integral-1);
                    }
                })
                if (rt == Integral) {
                    rt += '+';
                }
                return rt;
            },
            GetPartnerHonerRuleCustomerInfo: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerHonor/GetPartnerHonerRuleCustomerInfo",
                    data: {

                    },
                    OnSuccess: function (data) {
                        if (data.data != "") {
                            self.HonerRuleCustomerID = data.data.ID;
                            self.PullNew = data.data.PullNew;
                            self.Consult = data.data.Consult;
                            self.Intention = data.data.Intention;
                            self.Deal = data.data.Deal;
                            self.Read = data.data.Reads;
                        }
                        self.modalVisibleScore = true;
                    }
                };
                http.Post(op);
            },
            EditPartnerHonerRuleCustomer() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerHonor/EditPartnerHonerRuleCustomer",
                    data: {
                        ID: self.HonerRuleCustomerID,
                        PullNew: self.PullNew,
                        Consult: self.Consult,
                        Intention: self.Intention,
                        Deal: self.Deal,
                        Reads: self.Read,
                    },
                    OnSuccess: function (data) {
                        if (data.data) {
                            self.modalVisibleScore = false;
                            self.$message.success('保存成功', 1.5, function () {});
                        }
                    }
                };
                http.Post(op);
            },
        },
        watch: {//侦听属性
        },
        mounted: function () {//生命周期，组装完成
            this.getTemplateData();
            this.getTableData();
            //  this.jump();
        }

    };
</script>
<style>
    .honer-rule-footerno .ant-modal-footer {
        display: none;
    }
</style>